import './App.css';
import { ThemeProvider, createTheme, Arwes, Image } from 'arwes';

function App() {
  return (
    <ThemeProvider theme={createTheme()}>
      <Arwes>
        <div style={{ margin: '0 auto', padding: 20, maxWidth: 600, textAlign: 'center' }}>
            <p>
            Nezuko
            </p>
          <Image animate resources='/Nezuko.png'>
            Nezuko standing
            </Image>
        </div>
      </Arwes>
    </ThemeProvider>
  );
}

export default App;
